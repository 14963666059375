import React from "react";
import { useNavigate } from "react-router";
import { makeStyles } from "@mui/styles";
import { Typography, Button } from "@mui/material";
import success from "../../assets/images/success.png";
import ShareBar from "../../components/shared/ShareBar";
import Template from "../../components/shared/Template";

const useStyles = makeStyles((theme) => ({
  body: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

const Success = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  return (
    <Template
      title="Success!"
      subtitle="Your job has been submitted and we are notifying firefighters in your
          area of the opportunity to help."
      image={success}
      children={
        <div className={classes.body}>
          <Typography variant="subtitle2">Feeling productive?</Typography>
          <Button
            sx={{
              backgroundColor: "#B90E31",
              color: "white",
              width: { md: 259, sx: "90%" },
              height: 46,
              marginTop: "1.25rem",
            }}
            onClick={() => navigate("/")}
          >
            Start Another Project
          </Button>
          <Button
            sx={{
              backgroundColor: "#B90E31",
              color: "white",
              width: { md: 259, sx: "90%" },
              height: 46,
              marginTop: "1.25rem",
            }}
            onClick={() => navigate("/my-projects")}
          >
            My Projects
          </Button>
          <ShareBar />
        </div>
      }
    />
  );
};

export default Success;
