import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate, Navigate, useLocation } from "react-router";
import { useForm, Controller } from "react-hook-form";
import { makeStyles } from "@mui/styles";
import { login } from "../../redux/actions/userActions";
import {
  Typography,
  TextField,
  Link,
  Button,
  CircularProgress,
  IconButton,
} from "@mui/material";
import { toast } from "react-toastify";
import { useStatus } from "../../hooks/useStatus";
import {
  FULFILLED,
  PENDING,
  REJECTED,
} from "../../redux/constants/actionStatusConstants";
import { resetLoginStatus } from "../../redux/actions/statusActions";
import useSession from "../../hooks/useSession";
import { getUser } from "../../redux/actions/userActions";
import helpfulHeroesIcon from "../../assets/icons/helpful-heroes-icon@2.png";

const useStyles = makeStyles((theme) => ({
  loginBody: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginTop: "3rem",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "1rem",
      paddingRight: "1rem",
      marginTop: "3rem",
    },
  },
  loginForm: {
    display: "flex",
    flexDirection: "column",
    marginTop: "2rem",
    width: "25rem",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

let submitToken = false;

const LogIn = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const { authenticated, user } = useSession();
  const { status, error } = useStatus(login);
  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  const token = new URLSearchParams(search).get("token");

  React.useEffect(() => {
    if (authenticated) {
      async function identifyUser() {
        const userData = await dispatch(getUser(user));
        window.analytics.identify(userData.payload.user.id, {
          email: userData.payload.user.email,
          first_name: userData.payload.user.firstName,
          last_name: userData.payload.user.lastName,
        });
      }
      identifyUser();
    }
  });

  const onSubmit = async (data) => {
    const response = await dispatch(login(data));
    if (response.meta.statusRequest === FULFILLED) {
      navigate("/");
    } else if (response.meta.statusRequest === REJECTED) {
      toast.error(
        <Typography color="error">
          Before continue. You must verify your account to continue.
        </Typography>,
        {
          position: "top-center",
        }
      );
      navigate(`/verify-account?phoneNumber=${user?.phoneNumber}`);
    }
  };

  React.useEffect(() => {
    error &&
      toast.error(<Typography color="error">{error}</Typography>, {
        position: "top-center",
      });
    error && dispatch(resetLoginStatus());
  }, [error, dispatch]);

  if (authenticated && !!!user?.verifiedAt && !!user) {
    toast.error(
      <Typography color="error">
        Before continue. You must verify your account to continue.
      </Typography>,
      {
        position: "top-center",
      }
    );
    return <Navigate to={`/verify-account?phoneNumber=${user?.phoneNumber}`} />;
  }

  if (authenticated) {
    return <Navigate to={"/"} />;
  }

  if (!!token && !submitToken) {
    onSubmit({ token });
    submitToken = true;
  }

  return (
    <div className={classes.signUp}>
      <IconButton
        size="large"
        edge="start"
        sx={{ padding: 3, marginTop: "3rem" }}
        onClick={() => navigate("/")}
      >
        <img alt="helpful heroes" src={helpfulHeroesIcon} width="177.93px" />
      </IconButton>
      <div className={classes.loginBody}>
        <Typography variant="h3">Welcome back!</Typography>
        <form onSubmit={handleSubmit(onSubmit)} className={classes.loginForm}>
          <Controller
            name="email"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...register("email", {
                  required: true,
                  pattern:
                    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
                })}
                {...field}
                variant="outlined"
                placeholder="Email Address*"
                sx={{ width: "100%" }}
                error={errors.email}
                helperText={
                  (errors.email?.type === "required" && "Email is required.") ||
                  (errors.email?.type === "pattern" &&
                    "Email must be a valid email address.")
                }
              />
            )}
          />
          <Controller
            name="password"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...register("password", {
                  required: true,
                })}
                {...field}
                variant="outlined"
                placeholder="Password*"
                type="password"
                sx={{ width: "100%", mt: "1rem", mb: "0.5rem" }}
                error={errors.password}
                helperText={errors.password && "Password is required."}
              />
            )}
          />
          <Typography sx={{ alignSelf: "end", mb: "0.5rem" }} variant="body1">
            <Link
              sx={{ textDecoration: "none" }}
              onClick={() => navigate("/forgot-password")}
            >
              Forgot Password?
            </Link>
          </Typography>
          <Button
            type="submit"
            disabled={status === PENDING}
            sx={{ color: "white" }}
            variant="contained"
            color="navy"
          >
            {(status === PENDING && (
              <CircularProgress
                sx={{
                  color: "#002653",
                  width: "1.5rem !important",
                  height: "1.5rem !important",
                }}
              />
            )) ||
              "Log In"}
          </Button>
          <Typography sx={{ mt: "2rem" }} variant="h6">
            Don't have an account?{" "}
            <Link onClick={() => navigate("/sign-up")}>Sign up.</Link>
          </Typography>
        </form>
      </div>
    </div>
  );
};

export default LogIn;
