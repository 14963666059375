import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Typography,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { toast } from "react-toastify";
import CheckIcon from "@mui/icons-material/Check";
import Graph from "../../../assets/images/graph.svg";
import {
  FULFILLED,
  PENDING,
} from "../../../redux/constants/actionStatusConstants";
import useSession from "../../../hooks/useSession";
import { useDispatch } from "react-redux";
import { postJob, updateJob } from "../../../redux/actions/projectActions";
import { useProjectDetails } from "../../../hooks/useProject";
import { useStatus } from "../../../hooks/useStatus";

const useStyles = makeStyles((theme) => ({
  infoContainer: {
    display: "flex",
    paddingLeft: "26% !important",
    paddingRight: "26% !important",
    alignItems: "center !important",
    paddingBottom: "3rem !important",
    marginBottom: "5rem",

    [theme.breakpoints.down("sm")]: {
      paddingLeft: "5% !important",
      paddingRight: "5% !important",
    },
  },
  estimatePrices: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    position: "relative",
  },
  row: {
    display: "flex",
  },
  title: {
    display: "flex",
    alignItems: "baseline",
    justifyContent: "space-between",
    width: "95%",
  },
}));

const Estimate = ({
  expanded,
  setExpanded,
  handleChange,
  handleSubmit,
  selectedService,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSession();
  const { projectDetails } = useProjectDetails();
  const { status } = useStatus(postJob);
  const [updateLoading, setUpdateLoading] = React.useState(false);
  const [isViewed, setIsViewed] = React.useState(false);

  React.useEffect(() => {
    if (expanded === "panel3") {
      setIsViewed(false);
    }
  }, [expanded]);

  const handleContinue = async (data) => {
    if (!!user?.hasPaymentMethod) {
      let response = null;
      if (!projectDetails?.id && selectedService) {
        response = await dispatch(
          postJob({
            job: { ...data, serviceId: selectedService[0]?.id },
          })
        );
      } else {
        setUpdateLoading(true);
        if (selectedService) {
          response = await dispatch(
            updateJob({
              jobId: projectDetails.id,
              job: { ...data, serviceId: selectedService[0]?.id },
            })
          );
        }
      }

      if (response?.meta?.requestStatus === FULFILLED) {
        toast.success(
          <Typography color="success">
            Project was succesfully {projectDetails?.id ? "updated" : "created"}
            .
          </Typography>,
          {
            position: "top-center",
          }
        );
        setUpdateLoading(false);
        navigate("/success-project");
      } else {
        toast.error(
          <Typography color="error">{`${response?.error?.message}. Please verify the form again.`}</Typography>,
          {
            position: "top-center",
          }
        );
        setExpanded("panel1");
        setUpdateLoading(false);
      }
    } else {
      if (user === null) {
        toast.error(
          <Typography color="error">
            Please complete your contact information.
          </Typography>,
          {
            position: "top-center",
          }
        );
      } else {
        if (!user?.verifiedAt) {
          toast.error(
            <Typography color="error">Please verify your account.</Typography>,
            {
              position: "top-center",
            }
          );
        } else {
          if (!!!user?.hasPaymentMethod) {
            toast.error(
              <Typography color="error">
                Please add a payment method to continue.
              </Typography>,
              {
                position: "top-center",
              }
            );
          } else {
            if (localStorage.getItem("fireFightersNear") === "0") {
              toast.error(
                <Typography color="error">
                  Please add a new address.
                </Typography>,
                {
                  position: "top-center",
                }
              );
            }
          }
        }
      }
      setUpdateLoading(false);
    }
  };

  return (
    <Accordion
      expanded={expanded === "panel3"}
      sx={{
        boxShadow:
          expanded === "panel3" ? "0 20px 30px 0 rgba(0, 0, 0, 0.1)" : "none",
        marginBottom: "5rem",
      }}
      onChange={handleChange("panel3")}
    >
      <AccordionSummary aria-controls="panel3a-content" id="panel3a-header">
        <div className={classes.title}>
          <Typography variant="title1">Project Estimate</Typography>
          {isViewed && (
            <div
              className={classes.row}
              style={{
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Button
                variant="text"
                sx={{
                  color: "#2b8ced",
                  fontFamily: "Urbanist-Medium",
                  display: "flex",
                  fontsize: 14,
                  fontWeight: 500,
                  fontStretch: "normal",
                  fontStyle: "normal",
                  lineHeight: 1.43,
                  letterSpacing: "normal",
                  textAlign: "left",
                }}
              >
                View
              </Button>
              <CheckIcon color="green" />
            </div>
          )}
        </div>
      </AccordionSummary>
      <AccordionDetails className={classes.infoContainer}>
        <div className={classes.estimatePrices}>
          <img
            alt=""
            src={Graph}
            style={{ position: "absolute", width: "100%" }}
          />
          <div>
            <Typography
              sx={{ color: "navy !important", mb: "0.5rem" }}
              variant="subtitle1"
            >
              Low
            </Typography>
            {selectedService && (
              <Typography variant="body3">
                ${selectedService[0]?.lowestCost}
              </Typography>
            )}
          </div>
          <Divider
            sx={{ height: "11.9rem", borderStyle: "dashed" }}
            orientation="vertical"
          />
          <div>
            <Typography
              sx={{ color: "navy !important", mb: "0.5rem" }}
              variant="subtitle1"
            >
              Most Spend
            </Typography>
            {selectedService && (
              <Typography
                sx={{
                  color: "lightGreen !important",
                  fontFamily: "Urbanist-Medium",
                  fontWeight: "100",
                }}
                variant="subtitle1"
              >
                ${selectedService[0]?.lowestAvgCost} - $
                {selectedService[0]?.highestAvgCost}
              </Typography>
            )}
          </div>
          <Divider
            sx={{ height: "11.9rem", borderStyle: "dashed" }}
            orientation="vertical"
          />
          <div>
            <Typography
              sx={{
                color: "navy !important",
                mb: "0.5rem",
              }}
              variant="subtitle1"
            >
              High
            </Typography>
            {selectedService && (
              <Typography
                sx={{
                  fontFamily: "Urbanist-Medium",
                  fontWeight: "100",
                }}
                variant="subtitle1"
              >
                ${selectedService[0]?.highestCost}
              </Typography>
            )}
          </div>
        </div>
        {selectedService && (
          <Typography
            sx={{
              color: "lightGreen !important",
              fontFamily: "Urbanist-Medium",
              fontWeight: "100",
            }}
            variant="h1"
          >
            ${selectedService[0]?.avgCost}
          </Typography>
        )}
        <Typography
          sx={{ color: "navy !important", mt: "0.5rem" }}
          variant="subtitle1"
        >
          Helpful Heroes Average
        </Typography>
        <Typography
          sx={{ color: "black !important", textAlign: "center", mt: "1.5rem" }}
          variant="body1"
        >
          Your project estimate is based on trends in your area for your
          selected service. Don’t worry, you will only pay once your job is
          completed at a price mutually agreed upon with your assigned
          firefighter. Questions? Check out our{" "}
          <b
            style={{ cursor: "pointer" }}
            onClick={() => window.open("https://hidrent.com/faq")}
          >
            FAQs
          </b>{" "}
          to learn about the payment process.
        </Typography>
        <Button
          color="blue"
          sx={{
            color: "white",
            width: { md: "11.9rem", xs: "100%" },
            mt: "2rem",
          }}
          disabled={status === PENDING || updateLoading}
          variant="contained"
          onClick={handleSubmit(handleContinue)}
        >
          {!!user?.hasPaymentMethod &&
          localStorage.getItem("fireFightersNear") !== "0"
            ? "Submit Project"
            : "Continue"}
        </Button>
      </AccordionDetails>
    </Accordion>
  );
};

export default Estimate;
