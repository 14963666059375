import React from "react";
import TopBar from "../TopBar";
import { makeStyles } from "@mui/styles";
import { Typography, Button } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  body: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  bodyWidth: {
    width: "28.25rem",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
    marginTop: "0.438rem",
  },
  buttons: {
    width: 800,
    marginTop: 23,
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
}));

const Template = ({
  children,
  handleClick,
  button,
  image,
  title,
  subtitle,
}) => {
  const classes = useStyles();

  return (
    <div>
      <TopBar />
      <div className={classes.body}>
        <img alt="" src={image} style={{ width: "380px" }} />
        <Typography
          variant="title5"
          sx={{
            fontSize: {
              lg: "3.125rem !important",
              md: "3.125rem !important",
              sm: "2rem !important",
              xs: "2rem !important",
            },
          }}
        >
          {title}
        </Typography>
        <Typography variant="h6" className={classes.bodyWidth}>
          {subtitle}
        </Typography>
        <div className={classes.buttons}>{children}</div>
        {button && (
          <Button
            sx={{
              backgroundColor: "#B90E31",
              color: "white",
              width: { md: 259, sx: "90%" },
              height: 46,
              marginTop: "1.25rem",
              marginBottom: 15,
            }}
            onClick={() => handleClick()}
          >
            {button}
          </Button>
        )}
      </div>
    </div>
  );
};

export default Template;
